<template>
  <div>
    <h2 class="page-title-bar"><i class="ico ico-template"></i>{{ $t('Template Model Grouping') }}</h2>
    <!-- 검색창 -->
    <v-row>
      <v-col cols="8">
        <div class="searchArea">
          <v-text-field
            v-model="searchBy.groupName"
            @keyup.enter="searchGroups"
            :label="$t('Group List')"
            :placeholder="$t('Input group name')"
            class="form-input"
            outlined dense hide-details
            clearable
            @click:clear="resetsearch"
          ></v-text-field>
          <v-btn @click="searchGroups" text class="btn type-search ml-3">{{ $t('Search') }}</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="5">
        <!-- Template group list -->
        <v-data-table
          v-model="selectedGroups"
          @click:row="showGroupDetail"
          :headers="groupHeaders"
          :items="groups"
          item-key="groupName"
          :options.sync="groupOptions"
          show-select
          :hide-default-footer="true"
          class="tbl-type01 flat-type02 style03 grayline type3"
           height="300"
           :items-per-page="2000"

        >
          <template slot="no-data">
            <p> {{ $t('No data available') }} </p>
          </template>
        </v-data-table>
        <!-- Button group -->
        <div class="table-options">
          <div>
            <new-template-model-group
              :newBtnDisabled="newBtnDisabled"
              :user="user"
              :tagTypes="tagTypes"
              @resetGroups="resetGroups"
              @fireConfirm="confirm"
              :templateTypeList="templateTypeList"
              @getSearchData ='getSearchData($event)'
            />
            <edit-template-model-group
              :editDisabled="editBtnDisabled"
              :user="user"
              :tagTypes="tagTypes"
              :group="selectedGroup"
              :templates="templates"
              @resetGroups="resetGroups"
              @refreshTemplates="refreshTemplates"
              :templateTypeList="templateTypeList"
              :selectedTemplateList="selectedTemplateList"
               @getSearchData ='getSearchData($event)'
            />
            <v-btn
              @click="deleteGroups"
              :disabled="deleteBtnDisabled"
              class="btn-s ml-2"
              text
            >{{$t('Delete')}}</v-btn>
          </div>
          <!-- Pagination -->
          <!-- <div>
            <div class="pageInfo">{{ groupPageInfoText }}</div>
            <v-pagination
              v-model="groupPage"
              @input="groupPaging"
              :length="groupTotalPages*1"
              :total-visible="groupTotalVisiblePages"
              color="#2f3b4c"
            >
            </v-pagination>
          </div> -->
        </div>
      </v-col>
      <v-col cols="7">

<div class="customInfo" >
<div class="info-preview" style="width:680px;">
<h4 class="tit">{{ $t('TEMPLATE TYPE OF SELECTED GROUP') }}</h4>

<div class="" style="">
  <v-row>
    <v-col cols="5">
<v-data-table
                v-model="selectedTemplates"
                :headers="templateHeaders"
                :items="templates"
                item-key="name"
                :options.sync="templateOptions"
                :hide-default-footer="true"
                fixed-header
                class="tbl-type01 flat-type02 style03 grayline type3"
                height="235"
                :items-per-page="2000"
                @click:row="getImagePreview"
                single-select
                style="cursor:pointer"
              >
                <template slot="no-data">
                  <div>{{ noDataMsg }}</div>
                </template>
              </v-data-table>
    </v-col>
    <v-col cols="7">
        <div class="customInfo">
<div class="info-preview" style="width:480px">
<!-- <h4 class="tit">PREVIEW</h4> -->
<div class="preview">
<img :src="imageContent" class="img-label" />
</div>
</div>
</div>
    </v-col>
    <v-col style="margin-top:-20px">
      <div >{{ $t('Store') }}</div>
          <v-autocomplete   outlined prepend-inner-icon="mdi-magnify" dense  hide-details  v-model="selectedStore" @change="getarticleList(selectedStore,1)" :items="getStoreDetails" ></v-autocomplete>

    </v-col>
    <!-- <v-col style="margin-top:-20px">
      <div>{{ $t('Product') }}</div>
        <v-autocomplete   outlined prepend-inner-icon="mdi-magnify" dense  hide-details  :placeholder="`${$t('Input Product ID')}`"  v-model="selectedProduct"  @change="getImagePreview(selectedProduct)"   :items="getarticleListData" ></v-autocomplete>
    </v-col> -->
     <v-col cols="12" sm="6" style="margin-top:-20px">
        <div>{{ $t('Product') }}</div>
          <v-text-field
            v-model="searchProduct"
            outlined
            dense
            hide-details
            :placeholder="`${$t('Input Product ID')}`"
            class="form-input  in-btn"
            clearable
            style="margin-bottom:10px;padding-top: 0px;"
            @input="searchProductValue"
          >
           <template v-slot:append>
          <v-btn @click="handleSearch" text>
            <img src="@/assets/img/btn-search.jpg" alt="" />
          </v-btn>
        </template>
          </v-text-field>
          <v-autocomplete
     return-object
     autocomplete="off"
     outlined prepend-inner-icon="mdi-magnify"
     dense  hide-details
     :placeholder="`${$t('Input Product ID')}`"
     v-model="selectedProduct"
     @change="getImagePreview(selectedProduct)"
     item-text="articleId"
    item-value="articleId"
    :items="getarticleListData" >
                <template v-slot:append-item>
                  <div v-intersect="onIntersect" class="pa-4 teal--text">
                     <!-- <span v-if="!moreItemDisable">Loading more items ...</span> -->
                     <div class="loadingBar">
        <v-progress-circular v-if="!moreItemDisable"
          indeterminate
          :size="50"
          :width="7"
          color="#ddd"
        ></v-progress-circular>
      </div>
                  </div>
                </template>

                </v-autocomplete>
                                <!-- <infinite-loading @infinite="getarticleList" slot="append"></infinite-loading> -->

      </v-col>
  </v-row>
</div>
</div>
</div>
      </v-col>
    </v-row>
    <!-- Confirm Box -->
    <div>
      <v-dialog
        v-model="confirmBox.visible"
        persistent
        width="500"
      >
        <v-card class="popup add_store_popup" align="center" justify="center">
          <v-row >
            <v-col>
              <span v-text="confirmBox.msg"></span>
            </v-col>
          </v-row>
          <v-card-actions class="d-flex justify-center">
            <v-btn @click="handleClickConfirm(true)" text icon class="btn">{{ $t('Yes') }}</v-btn>
            <v-btn @click="handleClickConfirm(false)" text icon class="btn">{{ $t('No') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import { setTempObj, getTempObj } from '@/plugins/sessionStorageManager'

import NewTemplateModelGroup from './modal/NewTemplateModelGroup.vue'
import EditTemplateModelGroup from './modal/EditTemplateModelGroup.vue'
import axios from 'axios'
import configs from '@/plugins/configs'

const requests = {
  getImagepreviewData: {
    method: 'get',
    url: '/api/common/templates/preview'
  },
  geTemplateType: {
    method: 'get',
    url: '/api/common/templates/type'

  },
  getStoreList: {
    method: 'get',
    url: '/api/common/store/simple'
  },
  getArticleList: {
    method: 'get',
    url: '/api/common/articles'
  }

}
export default {
  name: 'TemplateGrouping',
  components: {
    NewTemplateModelGroup,
    EditTemplateModelGroup
  },
  data () {
    return {
      confirmBox: {
        visible: false,
        msg: ''
      },
      user: null,
      selectedGroup: null,
      selectedGroups: [],
      groupPage: 1,
      groupTotalPages: 10,
      groupPageInfoText: '',
      groupTotalVisiblePages: 5,
      groupOptions: {},
      totalGroups: null,
      groups: [],
      searchBy: { groupName: '' },
      selectedTemplates: [],
      templatePage: 1,
      templateTotalPages: 10,
      templatePageInfoText: '',
      templateTotalVisiblePages: 5,
      templateOptions: {},
      totalTemplates: null,
      templates: [],
      tagTypes: [],
      templateTypeList: [],
      newEditDeleteDisabled: false,
      imageContent: '',
      selectedTemplateList: [],
      true: true,
      getStoreDetails: [],
      selectedStore: null,
      getarticleListData: [],
      selectedProduct: null,
      page: 0,
      // pages: 0,
      perPage: 10,
      totalpage: 1,
      moreItemDisable: false,
      searchIconClick: false,
      inputSearch: false,
      searchProduct: null

    }
  },
  computed: {
    msg () {
      return {
        selectGroup: this.$t('Select the template group'),
        noData: this.$t('No data available')
      }
    },
    groupHeaders () {
      return [
        { text: this.$t('GROUP NAME'), value: 'groupName' },
        { text: this.$t('MODEL'), value: 'model' },
        { text: this.$t('LAST MODIFIED DATE'), value: 'lastModifiedDate' }
      ]
    },
    templateHeaders () {
      return [
        { text: '#', sortable: false, align: 'center', value: 'no', width: '30%' },
        { text: this.$t('NAME'), sortable: false, value: 'name' }
      ]
    },
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    newBtnDisabled () { return this.newEditDeleteDisabled },
    editBtnDisabled () { return this.newEditDeleteDisabled },
    deleteBtnDisabled () { return (this.selectedGroups.length < 1 || this.newEditDeleteDisabled) },
    noDataMsg () {
      if (this.selectedGroup === null) return this.$t(this.msg.selectGroup)
      return this.$t(this.msg.noData)
    }
  },
  watch: {
    groupOptions: {
      handler () {
        this.getGroups(1)
        this.groupPage = 1
      },
      deep: true
    },
    templateOptions: {
      handler () {
        if (this.templates.length > 0) this.getTemplates(1, this.selectedGroup.groupName)
      },
      deep: true
    },
    store: {
      handler () {
        this.getGroups(1)
      }
    },
    searchBy: {
      handler (newSearchBy) {
        const templateGrouping = {
          searchBy: newSearchBy
        }
        setTempObj('templateGrouping', templateGrouping)
      },
      deep: true
    }
  },
  created () {
    this.user = this.$store.state.auth.user
  },
  mounted () {
    this.clearTabindex()
    this.initiateTemplateGrouping()
    this.getStoreList()
    this.$store.dispatch('auth/getDisabledBtn', '5200').then(flag => {
      this.newEditDeleteDisabled = flag
    })
    EventBus.$emit('enableSelectedStores', false)
    const templateGroupingSessionData = getTempObj('templateGrouping')

    if (!commons.isNull(templateGroupingSessionData) && !commons.isNull(templateGroupingSessionData.searchBy)) {
      this.searchBy = templateGroupingSessionData.searchBy
    }
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    // reset data
    resetsearch () {
      this.searchBy.groupName = null
      this.getGroups(1)
    },
    // get store details

    getStoreList () {
      this.getStoreDetails = []
      // if (this.getareaKey === '' || this.getareaValue === undefined) {
      //   this.selectedStore = ''
      //   this.getStoreDetails = []
      //   this.selectedProduct = null
      //   return
      // }

      const params = {
        areaKey: this.$store.state.dataStore.permissionKey,
        areaValue: this.$store.state.dataStore.permissionValue
      }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getStoreList.method,
          requests.getStoreList.url,
          config
        )

        .then(res => {
          for (var i = 0; i < res.data.storeList.length; i++) {
            this.getStoreDetails.push(res.data.storeList[i].code)
            this.selectedStore = this.getStoreDetails[0]
          }
          if (this.selectboxvalue !== 1 && this.selectboxvalue !== undefined) {
            this.selectedStore = this.getStoreDetails[0]
          }
          this.selectedProduct = null
          this.getarticleListData = []
          this.searchProduct = null
          this.searchIconClick = false
          this.totalpage = 1
          this.page = 0
          this.getarticleList(this.selectedStore)
        })
        .catch(error => {
          console.debug(error)
        })
    },
    buildParmas (storeCode) {
      let params
      if (this.searchIconClick === true) {
        params = { id: this.searchProduct, store: storeCode, page: this.page, per_page: this.perPage, sort: 'id.articleId,asc' }
      } else {
        params = { store: storeCode, page: this.page, per_page: this.perPage, sort: 'id.articleId,asc' }
      }
      return params
    },
    // get article details
    getarticleList (storeCode, value) {
      this.selectboxvalue = value
      if (this.selectboxvalue === 1) {
        this.page = 1
        this.selectedProduct = null
        this.getarticleListData = []
        this.totalpage = 1
      }
      if (this.inputSearch === true) {
        this.getarticleListData = []
      }

      const params = this.buildParmas(storeCode)
      const config = { params: params }

      if (this.page > this.totalpage) {
        this.moreItemDisable = true
        return
      }
      this.$utils
        .callAxios(
          requests.getArticleList.method,
          requests.getArticleList.url,
          config
        )
        .then(res => {
          this.inputSearch = false
          this.totalpage = res.headers['x-totalpages'] - 1
          this.getarticleListData = [
            ...this.getarticleListData,
            ...res.data.articleList
          ]

          if (this.page >= this.totalpage) {
            this.moreItemDisable = true
          }
          this.selectedProduct = this.getarticleListData[0].articleId
          if (this.selectboxvalue === 1) {
            setTimeout(() => {
              this.getImagePreview(this.getarticleListData[0])
            }, 3000)
          }
          if (this.searchIconClick === true) {
            setTimeout(() => {
              this.getImagePreview(this.getarticleListData[0])
            }, 1000)
          }
        })
        .catch(error => {
          this.imageContent = ''
          console.debug(error)
        })
    },
    onIntersect () {
      console.log('load more...')
      this.page += 1
      this.getarticleList(this.selectedStore)
    },
    // get preview deatils based on store
    getImagePreview (item, row) {
      var article = ''
      this.imageContent = null
      var areaKey = this.$store.state.dataStore.permissionKey
      var areaValue = this.$store.state.dataStore.permissionValue
      var typeName1 = ''
      if (row === undefined) {
        typeName1 = this.selectedTemplates[0].name
      } else {
        typeName1 = item.name
      }
      if (row !== undefined) {
        row.select(true)
      }
      if (item.articleId === undefined) {
        article = this.selectedProduct
      } else {
        article = item.articleId
      }
      const params = { areaKey: areaKey, areaValue: areaValue, typeName: typeName1, storeCode: this.selectedStore, articleId: article, fileName: this.getFileName }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getImagepreviewData.method,
          requests.getImagepreviewData.url,
          config
        )
        .then(res => {
          var baseImage = 'data:image/bmp;base64,' + res.data.contentData

          this.imageContent = baseImage
        })
        .catch(error => {
          this.imageContent = ''
          console.log(error.response)
        })
    },
    // getimagepreview details
    getImagePreview1 (item, row) {
      var typeName1 = ''
      if (row === undefined) {
        typeName1 = this.selectedTemplates[0].name
      } else {
        typeName1 = item.name
      }
      if (row !== undefined) {
        row.select(true)
      }

      // var getArtilceDetails = this.$store.state.dataStore.savePreviewSet
      var areaKey = this.$store.state.dataStore.permissionKey
      var areaValue = this.$store.state.dataStore.permissionValue
      // if (getArtilceDetails[0] === undefined) {
      //   params = { typeName: item.name, areaKey: areaKey, areaValue: areaValue }
      // } else {
      const params = { areaKey: areaKey, areaValue: areaValue, typeName: typeName1, storeCode: this.selectedStore, articleId: this.selectedProduct }
      // }

      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getImagepreviewData.method,
          requests.getImagepreviewData.url,
          config
        )
        .then(res => {
          var baseImage = 'data:image/bmp;base64,' + res.data.contentData

          this.imageContent = baseImage
        })
        .catch(error => {
          console.debug(error)
          if (error.response.status === 405) {
            this.imageContent = ''
          } if (error.response.status === 500) {
            this.imageContent = ''
          }
        })
    },

    // handle article search
    handleSearch () {
      this.page = 0
      if (this.searchProduct == null || this.searchProduct === '') {
        EventBus.$emit('messageAlert', 'Please Input Product ID')
        return
      }
      this.searchIconClick = true
      this.inputSearch = true
      this.getarticleList(this.selectedStore)
      // this.getSelectedImagePreview(this.searchProduct)
    },
    searchProductValue () {
      if (this.searchProduct == null && this.searchIconClick === true) {
        this.page = 0
        this.getarticleListData = []
        this.getarticleList(this.selectedStore)
      }
    },
    confirm (msg, callback) {
      this.confirmBox.msg = msg
      this.confirmBox.visible = true
      this.confirmBox.callback = callback
    },
    handleClickConfirm (response) {
      if (response) this.confirmBox.callback()
      this.confirmBox.visible = false
    },
    refreshTemplates () { this.getTemplates(1, this.selectedGroup.groupName) },
    initiateTemplateGrouping () {
      this.getGroups(1)
      this.getTagTypes()
      this.getTemplateType()
    },
    groupPaging () { this.getGroups(this.groupPage) },
    templatePaging () { this.getTemplates(this.templatePage, this.selectedGroup) },
    getPageInfoText: function (headers) {
      if (headers['x-totalelements'] === 0) return '0 to 0, 0' + ' ' + this.$t('in total')
      return `${headers['x-number'] * 1 * headers['x-size'] + 1} 
      ${this.$t('to')} ${(headers['x-number'] * 1 * headers['x-size']) + headers['x-total-count'] * 1},
       ${headers['x-totalelements'] * 1}` + ' ' + this.$t('in total')
    },
    resetGroups () {
      this.getGroups(1)
      this.groupPage = 1
      this.selectedGroups = []
      this.templates = []
      this.selectedTemplates = []
    },
    insertIndex (templates) {
      let idx = 1 * this.templatePage
      templates = templates.map(template => {
        const templateObj = {}
        templateObj.no = idx
        templateObj.name = template
        templateObj.lastModifiedDate = commons.convertDate(templates.lastModifiedDate)
        idx++
        return templateObj
      })
      return templates
    },
    convertProductsDate (grouplist) {
      return grouplist.map(product => {
        product.lastModifiedDate = commons.convertDate(product.lastModifiedDate)
        return product
      })
    },
    handleGetGroupsResponse (res) {
      if (res.status === 204) {
        this.handleGetGroupsError('No content')
        return
      }
      // Refresh
      this.groups = this.convertProductsDate(res.data.groupList)
      // this.selectedGroups.push(res.data.groupList[0])
      this.totalGroups = this.groups.length
      this.selectedGroup = null
      this.templates = []
      // Pagination
      this.groupPageInfoText = this.getPageInfoText(res.headers)
      this.groupTotalPages = res.headers['x-totalpages']
      this.selectedGroups = []
    },
    handleGetGroupsError (error) {
      this.groups = []
      this.totalGroups = this.groups.length
      const headers = {}
      headers['x-totalelements'] = 0
      this.groupPageInfoText = this.getPageInfoText(headers)
      this.groupTotalPages = 0
      this.selectedGroups = []
      this.selectedGroup = null
      this.templates = []
      console.debug(`Could not find any group ${error}`)
    },
    handleGetTempaltesResponse (res) {
      if (res.status === 204) {
        this.handleGetTempaltesError('No content')
        return
      }
      // Refresh
      // this.templates = this.insertIndex(res.data.templateNameList)
      this.templates = this.insertIndex(res.data.templateNameList)
      this.selectedTemplateList = []
      var selectedTemplateList = this.templates
      this.templateTypeList.filter(template => {
        for (const gt of selectedTemplateList) {
          if (gt.name === template.typeName) {
            this.selectedTemplateList.push({
              typeName: template.typeName,
              templateSize: template.templateSize,
              icon: true
            })
            return false
          }
        }
        return true
      })
      // this.selectedGroups.push(res.data.templateNameList[0])
      this.totalTemplates = this.templates.length
      // Pagination
      this.templatePageInfoText = this.getPageInfoText(res.headers)
      this.templateTotalPages = Math.floor(this.totalTemplates / this.templateOptions.itemsPerPage)
      this.selectedTemplates = []
    },
    handleGetTempaltesError (error) {
      this.templates = []
      this.totalTemplates = this.templates.length
      const headers = {}
      headers['x-totalelements'] = 0
      this.templatePageInfoText = this.getPageInfoText(headers)
      this.templateTotalPages = 0
      this.selectedTemplates = []
      this.selectedTemplateList = []
      console.debug(`Could not find any template ${error}`)
    },

    getGroupSortKey: function (sortKey) {
      switch (sortKey) {
        case 'groupName': sortKey = 'name'
          break
        case 'lastModifiedDate': sortKey = 'lastModified'
          break
        default: break
      }
      return sortKey
    },
    buildGetGroupsParmas () {
      const { sortBy, sortDesc, itemsPerPage } = this.groupOptions
      const params = { company: this.user.company }
      if (this.searchBy.groupName !== null) {
        if (this.searchBy.groupName.length > 0) params.name = this.searchBy.groupName
      }
      let sort = null
      if (commons.isSorting(sortBy, sortDesc)) {
        const sortKey = this.getGroupSortKey(sortBy[0])
        const sortOrder = sortDesc[0]
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      if (itemsPerPage > 0) params.size = 2000
      return params
    },
    getGroups (page) {
      const params = this.buildGetGroupsParmas()
      if (page !== null) params.page = page - 1
      const config = { params: params }
      const url = '/metro/common/templates/mapping/group'
      axios.get(configs.ServerAddress + url, config)
        .then(res => {
          this.handleGetGroupsResponse(res)
        })
        .catch(error => {
          this.handleGetGroupsError(error)
        })
    },
    buildGetTemplatesParams () {
      const { sortBy, sortDesc, itemsPerPage } = this.templateOptions
      const params = { company: this.user.company }
      if (this.searchBy.groupName !== null) {
        if (this.searchBy.groupName.length > 0) params.name = this.searchBy.groupName
      }
      let sort = null
      if (commons.isSorting(sortBy, sortDesc)) {
        const sortKey = sortBy[0]
        const sortOrder = sortDesc[0]
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      if (itemsPerPage > 0) params.size = itemsPerPage
      return params
    },
    getTemplates (page, groupName) {
      const params = this.buildGetTemplatesParams()
      params.group = groupName
      if (page !== null) params.page = page - 1
      const config = { params: params }
      // this.$utils
      //   .callAxios(
      //     codes.requests.getGroupDetail.method,
      //     codes.requests.getGroupDetail.url,
      //     config
      //   )
      const url = '/metro/common/templates/mapping/group/detail'
      axios.get(configs.ServerAddress + url, config)
        .then(res => {
          this.handleGetTempaltesResponse(res)
        })
        .catch(error => {
          this.handleGetTempaltesError(error)
        })
    },
    getTagTypes () {
      const params = { company: this.user.company }
      const config = { params: params }
      this.$utils
        .callAxios(
          codes.requests.getTagTypes.method,
          codes.requests.getTagTypes.url,
          config
        )
        .then(res => {
          this.tagTypes.push({ name: 'All' })

          for (const gt of res.data.tagTypeList) {
            this.tagTypes.push({
              name: gt.name
            })
          }
        })
    },
    getSearchData (items) {
      if (items === 'All') {
        this.getTemplateType()
      } else {
        this.searchTemplateType(items)
      }
    },
    searchTemplateType (items) {
      const params = { templateSize: items, size: '2000' }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.geTemplateType.method,
          requests.geTemplateType.url,
          config
        )
        .then(res => {
          this.templateTypeList = res.data
        })
    },

    getTemplateType () {
      const params = { size: '2000' }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.geTemplateType.method,
          requests.geTemplateType.url,
          config
        )
        .then(res => {
          this.templateTypeList = res.data
        })
    },
    searchGroups () {
      this.getGroups(1)
      this.groupPage = 1
    },
    deleteGroups () {
      if (this.selectedGroups.length < 1) return
      const data = { templateGroupDeleteList: [] }
      const params = { company: this.user.company }
      for (const group of this.selectedGroups) {
        data.templateGroupDeleteList.push(group.groupName)
      }
      if (this.selectedGroups.length < 1) return
      const config = {
        data: data,
        params: params
      }
      // this.$utils
      //   .callAxios(
      //     codes.requests.deleteGroups.method,
      //     codes.requests.deleteGroups.url,
      //     config
      //   )
      const url = '/metro/common/templates/mapping/group'
      axios.delete(configs.ServerAddress + url, config)
        .then(res => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          if (res.data.responseCode === '200') {
            this.resetGroups()
          }
        })
        .catch(error => {
          EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
        })
    },
    showGroupDetail (item) {
      this.groups.map(group => {
        group.selected = false
        return group
      })
      item.selected = true
      this.groups = [...this.groups]
      this.selectedGroup = item
      this.getTemplates(1, item.groupName)
      this.imageContent = ''
    },
    getRowClass: commons.getRowClass
  }
}
</script>

<style scoped >
  .selected-group{min-height:100%; padding:30px; background:#fafafa;}
  .selected-group h3{font-size:var(--font14); color:#a3a6b4; font-weight:500;}
  .selected-group h4{margin-bottom:10px; font-size:var(--font13); color:#a3a6b4; font-weight:400;}
  .preview{height:100%; padding:0 10px; background:#fff;}

  .row-class:hover { cursor: pointer; }
  ::v-deep .tbl-type01.style03
th {
    background: #ececec !important;
}

::v-deep tr.v-data-table__selected td{
  background: #09263f !important
}
::v-deep tr.v-data-table__selected td{
  color: #ffffff !important
}
::v-deep tr.v-data-table__selected .mdi-checkbox-marked::before {
  color: #ffffff !important;
}
::v-deep .type3{
    border: 1px solid lightgray;
}
::v-deep .customInfo .info-preview .preview{
  height:236px
}

</style>
