<template>
  <v-dialog
    v-model="editTemplateGroupVisible"
    @click:outside="cancelEditTemplateGroup"
    :key="editTemplateGroupVisible"
    width="1000"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="editBtnDisabled" @click="setGroupData" text v-bind="attrs" v-on="on" class="btn-s ml-2">{{$t('Edit')}}</v-btn>
    </template>

    <v-card class="popup add_store_popup">
      <h3 class="page-title-bar">
        <i class="ico ico-template"></i>{{$t('TEMPLATE EDIT')}}
      </h3>
<v-row >
  <v-col cols="6">
    <section class="searchArea">
      <v-select
        v-model="editTemplateTypeList"
        :items="tagTypes"
        item-text="name"
        class="form-select ml-2"
        outlined dense hide-details
        :placeholder="`${$t('Select template size')}`"
        style="padding-right:13px !important"
        :label="$t('Template Size')"
        @change="selectTemplateSize"
      ></v-select>
      <v-text-field

        v-model="search"
        outlined
        dense
        hide-details
        :placeholder="`${$t('Input Type Name')}`"
        class="form-input"
        clearable
        :label="$t('Type Name')"
        @keydown="clearTabindex"
      >
      </v-text-field>
      <v-text-field
        v-model="model"
        outlined
        dense
        hide-details
        :placeholder="`${$t('Input Model')}`"
        class="form-input ml-3"
        clearable
        :label="$t('Model')"
        disabled
        @keydown="clearTabindex"
      >
      </v-text-field>
    </section>
     <v-data-table
       :headers="templateHeadersLeft"
       :items="templateTypeList"
       item-key="typeName"
       :hide-default-footer="true"
       height="250"
       class="tbl-type01 flat-type02 style03 grayline "
       :items-per-page="2000"
       single-select
       style="padding-left:10px;padding-top:30px;cursor:pointer"
       :search="search"
       @click:row="selectTemplateype"

      >
      <template slot="no-data">
        <p>
          {{ $t('No data available') }}
        </p>
      </template>
    </v-data-table>

  </v-col>
   <v-col cols="1">
     <v-btn
            @click="groupTemplates"
            style="height:248px;margin-top:100px;width:1px;"
            class="red"
            dark
            > {{$t('Add')}}</v-btn
          >
  </v-col>
   <v-col cols="5">
     <section class="searchArea">
     <v-text-field
            v-model="groupName"
            :placeholder="`${$t('Input the group name')}`"
            :label="$t('TEMPLATE GROUP NAME')"
            class="form-input"
            disabled
            outlined
            dense
            hide-details

          >
          </v-text-field>
    </section>
     <v-data-table
          v-model="selectedGroupedTemplates"
          :headers="templateHeaders"
          :items="groupedTemplates"
          item-key="sno"
          :hide-default-footer="true"
          height="250"
          class="tbl-type01 flat-type02 style03 grayline "
          :items-per-page="2000"
          single-select
          style="padding-left:10px;padding-top:30px;"

      >

    <template slot="no-data">
        <p>
          {{ $t('No data available') }}
        </p>
      </template>
      <template  v-slot:[`item.icon`]="{ item }">
       <v-icon  style="color:red" @click="deleteSelectedTemplate(item)"   medium>mdi-close-circle </v-icon>
      </template>

    </v-data-table>
  </v-col>
</v-row>
      <v-card-actions class="d-flex justify-center">
        <v-btn text icon @click="editGroup" class="btn"
          >{{$t('Save')}}</v-btn
        >
        <v-btn
          text icon
          @click="cancelEditTemplateGroup"
          class="btn"
          >{{$t('Cancel')}}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/plugins/eventBus.js'
import commons from '@/plugins/commons'
import axios from 'axios'
import configs from '@/plugins/configs'

// const requests = {
//   editGroup: {
//     method: 'put',
//     url: '/api/common/templates/mapping/group'
//   }

// }

export default {
  props: ['user', 'tagTypes', 'group', 'templates', 'editDisabled', 'templateTypeList', 'selectedTemplateList'],
  data () {
    return {
      // cloud 용 임시
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      groupName: null,
      selectedGroupedTemplates: [],
      editTemplateGroupVisible: false,
      groupedTemplatesHeaders: [
        { text: this.$t('TEMPLATE NAME'), sortable: false, value: 'templateName' },
        { text: '', sortable: false, align: 'center', value: 'del' }
      ],
      templatesByTagHeaders: [
        { text: this.$t('TEMPLATE NAME'), sortable: false, value: 'templateName' },
        {
          text: this.$t('GENERATE'),
          sortable: false,
          align: 'center',
          value: 'lastUpdateTime'
        }

      ],
      templatesByTag: [],
      editTemplateTypeList: { name: 'All' },
      search: '',
      selectedTemplateType: [],
      groupedTemplates: [],
      model: null

    }
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    selectTemplateSize (items) {
      this.$emit('getSearchData', items)
    },
    selectTemplateype (item, row) {
      row.select(true)
      this.selectedTemplateType = []
      this.selectedTemplateType.push(item)
    },
    setGroupData () {
      this.editTemplateTypeList = { name: 'All' }
      this.setGroupName()
      this.setModel()
      this.groupedTemplates = this.selectedTemplateList
    },
    setGroupName () {
      this.groupName = this.group.groupName
    },
    setModel () {
      this.model = this.group.model
    },
    cancelEditTemplateGroup () {
      this.resetEditTemplateGroup()
      this.editTemplateGroupVisible = !this.editTemplateGroupVisible
    },
    resetEditTemplateGroup () {
      this.groupedTemplates = []
      this.templatesByTag = []
      this.selectedGroupedTemplates = []
      this.editTemplateTypeList = 'All'
      this.$emit('getSearchData', this.editTemplateTypeList)
      this.search = ''
    },

    buildEditGroupBody () {
      const editGroupBody = {}
      var groupedTemplate = this.groupedTemplates
      const templateNameList = groupedTemplate.map(gt => gt.typeName)
      editGroupBody.templateNameList = templateNameList
      return editGroupBody
    },
    editGroup () {
      const editGroupBody = this.buildEditGroupBody()
      const isValidData = commons.isValidData(editGroupBody)
      if (!isValidData.result) {
        EventBus.$emit('messageAlert', this.$t(isValidData.msg))
        return
      }
      const params = { company: this.user.company, group: this.groupName }
      const config = { params: params }
      if (this.productionType === 'cloud') {
        params.company = this.$store.state.auth.user.company
      }
      // this.$utils
      //   .callAxiosWithBody(
      //     requests.editGroup.method,
      //     requests.editGroup.url,
      //     editGroupBody,
      //     config
      //   )
      //   .then((res) => {
      const url = '/metro/common/templates/mapping/group'
      axios.put(configs.ServerAddress + url, editGroupBody, config)
        .then(res => {
          const result = res.data
          EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          this.cancelEditTemplateGroup()
          this.$emit('resetGroups')
        })
        .catch((error) => {
          EventBus.$emit('messageAlert', this.$t('Failed to edit the template group.'))
          this.cancelEditTemplateGroup()
          console.log(error)
        })
    },

    groupTemplates () {
      const templates = this.selectedTemplateType
      // Remove redundant templates beforehand.
      const template = templates.filter(template => {
        for (const gt of this.groupedTemplates) {
          if (gt.templateSize === template.templateSize) {
            EventBus.$emit('messageAlert', this.$t('Same template size is already selected.'))
            return false
          }
        }
        return true
      })
      // Check if user is trying to add a redundant type of template
      if (template.length !== 0) {
        this.groupedTemplates.push({
          typeName: template[0].typeName,
          templateSize: template[0].templateSize,
          icon: true
        })
      }
    },
    deleteSelectedTemplate (item) {
      var removeIndex = this.groupedTemplates.map(function (item) { return item.typeName }).indexOf(item.typeName)
      this.groupedTemplates.splice(removeIndex, 1)
    }
  },
  computed: {
    templateHeadersLeft () {
      return [
        { text: this.$t('TEMPLATE SIZE'), value: 'templateSize' },
        { text: this.$t('TYPE NAME'), value: 'typeName' }

      ]
    },
    templateHeaders () {
      return [
        { text: this.$t('TEMPLATE SIZE'), value: 'templateSize' },
        { text: this.$t('TYPE NAME'), value: 'typeName' },
        { text: '', value: 'icon' }

      ]
    },
    editBtnDisabled () {
      return (this.group === null || this.group === undefined || this.editDisabled)
    }
  }
}
</script>

<style>
.scroll-tbl{height:495px; overflow-y:auto;}
/* width */
.scroll-tbl::-webkit-scrollbar {width: 5px;}
/* Track */
.scroll-tbl::-webkit-scrollbar-track {background: transparent;}
/* Handle */
.scroll-tbl::-webkit-scrollbar-thumb {background: #818181; border-radius:5px;}
/* Handle on hover */
.scroll-tbl::-webkit-scrollbar-thumb:hover {background: #555;}
</style>
<style scoped>
::v-deep tr.v-data-table__selected {
    background:  lightgray !important;
  }
  ::v-deep .tbl-type01.style03
th {
    background: #ececec !important;
}
</style>
