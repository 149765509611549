var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      key: _vm.editTemplateGroupVisible,
      attrs: { width: "1000" },
      on: { "click:outside": _vm.cancelEditTemplateGroup },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn-s ml-2",
                      attrs: { disabled: _vm.editBtnDisabled, text: "" },
                      on: { click: _vm.setGroupData }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("Edit")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.editTemplateGroupVisible,
        callback: function($$v) {
          _vm.editTemplateGroupVisible = $$v
        },
        expression: "editTemplateGroupVisible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("h3", { staticClass: "page-title-bar" }, [
            _c("i", { staticClass: "ico ico-template" }),
            _vm._v(_vm._s(_vm.$t("TEMPLATE EDIT")) + " ")
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "section",
                    { staticClass: "searchArea" },
                    [
                      _c("v-select", {
                        staticClass: "form-select ml-2",
                        staticStyle: { "padding-right": "13px !important" },
                        attrs: {
                          items: _vm.tagTypes,
                          "item-text": "name",
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          placeholder: "" + _vm.$t("Select template size"),
                          label: _vm.$t("Template Size")
                        },
                        on: { change: _vm.selectTemplateSize },
                        model: {
                          value: _vm.editTemplateTypeList,
                          callback: function($$v) {
                            _vm.editTemplateTypeList = $$v
                          },
                          expression: "editTemplateTypeList"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          placeholder: "" + _vm.$t("Input Type Name"),
                          clearable: "",
                          label: _vm.$t("Type Name")
                        },
                        on: { keydown: _vm.clearTabindex },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "form-input ml-3",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          placeholder: "" + _vm.$t("Input Model"),
                          clearable: "",
                          label: _vm.$t("Model"),
                          disabled: ""
                        },
                        on: { keydown: _vm.clearTabindex },
                        model: {
                          value: _vm.model,
                          callback: function($$v) {
                            _vm.model = $$v
                          },
                          expression: "model"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-data-table",
                    {
                      staticClass: "tbl-type01 flat-type02 style03 grayline ",
                      staticStyle: {
                        "padding-left": "10px",
                        "padding-top": "30px",
                        cursor: "pointer"
                      },
                      attrs: {
                        headers: _vm.templateHeadersLeft,
                        items: _vm.templateTypeList,
                        "item-key": "typeName",
                        "hide-default-footer": true,
                        height: "250",
                        "items-per-page": 2000,
                        "single-select": "",
                        search: _vm.search
                      },
                      on: { "click:row": _vm.selectTemplateype }
                    },
                    [
                      _c("template", { slot: "no-data" }, [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("No data available")) + " "
                          )
                        ])
                      ])
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "red",
                      staticStyle: {
                        height: "248px",
                        "margin-top": "100px",
                        width: "1px"
                      },
                      attrs: { dark: "" },
                      on: { click: _vm.groupTemplates }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Add")))]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c(
                    "section",
                    { staticClass: "searchArea" },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          placeholder: "" + _vm.$t("Input the group name"),
                          label: _vm.$t("TEMPLATE GROUP NAME"),
                          disabled: "",
                          outlined: "",
                          dense: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.groupName,
                          callback: function($$v) {
                            _vm.groupName = $$v
                          },
                          expression: "groupName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-data-table",
                    {
                      staticClass: "tbl-type01 flat-type02 style03 grayline ",
                      staticStyle: {
                        "padding-left": "10px",
                        "padding-top": "30px"
                      },
                      attrs: {
                        headers: _vm.templateHeaders,
                        items: _vm.groupedTemplates,
                        "item-key": "sno",
                        "hide-default-footer": true,
                        height: "250",
                        "items-per-page": 2000,
                        "single-select": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.icon",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { medium: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteSelectedTemplate(item)
                                      }
                                    }
                                  },
                                  [_vm._v("mdi-close-circle ")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.selectedGroupedTemplates,
                        callback: function($$v) {
                          _vm.selectedGroupedTemplates = $$v
                        },
                        expression: "selectedGroupedTemplates"
                      }
                    },
                    [
                      _c("template", { slot: "no-data" }, [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("No data available")) + " "
                          )
                        ])
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.editGroup }
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.cancelEditTemplateGroup }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }